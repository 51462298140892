import { type Prisma, UserProvider } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { SamlAuthenticationEnforcedError } from "~/lib/errors/samlAuthenticationEnforcedError";
import { assertNotNil } from "~/lib/utils";
import { type ProfileData } from "~/services/auth/profileData";

export const selectCompanyForSamlAuthentication = {
  id: true,
  emailDomains: true,
  samlConfiguration: {
    select: {
      companyDomainsEnforced: true,
      enforceSamlAuthentication: true,
    },
  },
} satisfies Prisma.CompanySelect;

export const enforceSamlAuthentication = async (
  ctx: AppContext,
  params: {
    company: Prisma.CompanyGetPayload<{ select: typeof selectCompanyForSamlAuthentication }>;
    profileData: Pick<ProfileData, "provider" | "email">;
  }
) => {
  if (params.profileData.provider === UserProvider.SAML) return;

  if (!params.company.samlConfiguration?.enforceSamlAuthentication) return;

  const user = await ctx.prisma.user.findUnique({
    where: {
      email: params.profileData.email,
      companyId: params.company.id,
    },
    select: { permissions: { select: { canSetupSSO: true } } },
  });

  if (user && user.permissions.canSetupSSO) return;

  if (params.company.emailDomains.length > 1) {
    const emailDomain = assertNotNil(params.profileData.email.split("@").pop());

    if (!params.company.samlConfiguration.companyDomainsEnforced.includes(emailDomain.toLowerCase())) return;
  }

  throw new SamlAuthenticationEnforcedError(params.profileData.email);
};
